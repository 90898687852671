<template>
  <div
    :class="`border-l-8 ${classes} p-2 bg-white rounded-2lg shadow text-center flex items-center`"
    @click="handleClick"
  >
    <span class="flex-1">
      <slot />
    </span>
    <font-awesome-icon
      v-if="withCloseButton"
      class="cursor-pointer"
      icon="times"
      @click="handleClose"
    />
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue'

const props = defineProps({
  // Whether the snackbar
  // will close automatically
  autoClose: {
    type: Boolean,
    default: true,
  },
  // Time (in ms) to auto close
  autoCloseDuration: {
    type: Number,
    default: 5000,
  },
  // Color to use
  color: {
    type: String,
    default: null,
  },
  // Whether a close
  // button will be displayed
  withCloseButton: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits([
  'closed',
  'clicked',
])

const classes = computed(() => {
  const classes = []
  const color = props.color ?? 'theme'

  classes.push(`border-${color}-500`)

  return classes.join(' ')
})

function handleClose() {
  emits('closed')
}

function handleClick() {
  emits('clicked')
}

onMounted(() => {
  if (props.autoClose) {
    setTimeout(() => {
      handleClose()
    }, props.autoCloseDuration)
  }
})
</script>
