<template>
  <div
    ref="flashRef"
    :class="`my-1 swipe-${swipeDirection}`"
    :data-swipable="true"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <app-snackbar
      :color="color"
      :auto-close="autoClose"
      :auto-close-duration="autoCloseDuration"
      :with-close-button="false"
      @clicked="closeCallback"
      @closed="closeCallback"
    >
      {{ message }}
    </app-snackbar>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  nextTick,
} from 'vue'
import AppSnackbar from '@shared/components/ui/AppSnackbar.vue'

const props = defineProps({
  // Whether the flash
  // will close automatically
  autoClose: {
    type: Boolean,
    default: true,
  },
  // Time (in ms) to auto close
  autoCloseDuration: {
    type: Number,
    default: 5000,
  },
  // Determine the design
  // (danger, success...)
  type: {
    type: String,
    default: null,
  },
  // Text to display
  message: {
    type: String,
    default: null,
  },
  // Function called
  // on close handling
  closeCallback: {
    type: Function,
    default: () => ({}),
  },
})

// color to use for snackbar
const color = computed(() => (
  props.type === 'danger' ? 'red' : null
))

// ---------- TOUCH TO SWIPE FLASH ----------

const startX = ref(null) // x coord at touch start
const endX = ref(null) // x coord at touch end
const flashRef = ref(null) // ref to flash element
const swipeDirection = ref('right')

// Swipe distance is 2/5 of the flash's width
const swipeDistance = computed(() => (
  (
    (flashRef.value?.clientWidth ?? 0)
    * (2 / 5)
  )
))

function handleTouchStart(e) {
  // only if 1 finger touched the device
  if (e.changedTouches?.length === 1) {
    // save the touch start position
    startX.value = e.changedTouches[0].clientX
  }
}

function handleTouchEnd(e) {
  // Only if 1 finger touched the device
  if (e.changedTouches?.length === 1 && startX.value) {
    // Save the touch end position
    endX.value = e.changedTouches[0].clientX

    // Check if touch is to left or right
    if (startX.value < endX.value) {
      handleTouchToRight()
    } else {
      handleTouchToLeft()
    }

    endX.value = null
    startX.value = null
  }
}

async function handleTouchToRight() {
  // Close if
  // touch distance is longer enough
  if (endX.value - startX.value >= swipeDistance.value) {
    swipeDirection.value = 'right'
    await nextTick()
    props.closeCallback()
  }
}

async function handleTouchToLeft() {
  // Close if
  // touch distance is longer enough
  if (startX.value - endX.value >= swipeDistance.value) {
    swipeDirection.value = 'left'
    await nextTick()
    props.closeCallback()
  }
}
</script>
